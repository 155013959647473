import {Link} from'react-router-dom';
import { Image, Card, Row, Col} from 'antd';


export default function DesignItemC( {DesignC}  ){
    
    return(
        <Link to={`/ItemDetailService/${DesignC.id}` } >
            <div className='item-box type'>
                <Row className='item-box-row'justify="center">
                    
                <Col>
                        <div>
                            <div className='design-rwd-box '>
                                    <div className='design-content' style={{flex: "1"}}>
                                        <div className='design-tag'>{DesignC.tag}</div>
                                        <div className='design-name mt-20'>{DesignC.title}</div>
                                        <div className='design-slogan'>{DesignC.slogan}</div>
                                    </div>
                                    <div style={{flex: "1"}}>
                                        <Image 
                                            alt="example" 
                                            preview={false}
                                            src={require(`../images/projects/interactive/${DesignC.image}`)}/>
                                        
                                    </div>
                            </div>
                            <div>
                                <div className='design-award mt-5'>{DesignC.award0}</div>
                                <div className='design-award'>{DesignC.award1}</div>
                                <div className='design-award'>{DesignC.award2}</div>
                            </div>
                        </div>
                        
                        
                    </Col>
                </Row>
                
            </div>
        </Link>
        


    )
}