import {Row, Col } from 'antd';
import { Carousel } from 'antd';

import {MailOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined} from '@ant-design/icons';

import profolio1 from '../images/contact/profolio (1).jpg';
import profolio2 from '../images/contact/profolio (2).jpg';
import profolio3 from '../images/contact/profolio (3).jpg';
import profolio4 from '../images/contact/profolio (4).jpg';

export default function Contact(){

    return(
        <div>
            <div className="container-fluid">
                <Row className="box-about" justify="space-around" >
                  <Col 
                    xs={{ span: 24}}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                        <div className="box-title"> </div>
                        <div className="box-slogan">Contact me</div>
                        <div className="box-content-p">In order to avoid full disclosure of the design information, if you are interested in the projects listed on the website, you can contact us via email to get the full version of the portfolio with the pdf file.</div>

                        <div className="skill-popover-contact mt-20">
                            <div className="skill-item"> william.apple.show@gmail.com</div>
                            
                            
                        </div>
                        

                    </Col>
                    <Col 
                      xs={{ span: 20}}
                      sm={{ span: 20 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      className='carousel'
                    >
                      <Carousel autoplay>
                        <div>
                          <h3>
                            <img src={ profolio1 } className='contact-image'/>
                          </h3>
                        </div>
                        <div>
                          <h3>
                            <img src={profolio2} className='contact-image'/>
                          </h3>
                        </div>
                        <div>
                          <h3>
                            <img src={profolio3} className='contact-image'/>
                          </h3>
                        </div>
                        <div>
                          <h3>
                            <img src={profolio4} className='contact-image'/>
                          </h3>
                        </div>
                      </Carousel>
                    </Col>
                
                    
                </Row>
                

            </div>
            
        </div>
    );
}