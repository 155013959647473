import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import Cate_Play from "../components/Cate_Play";


const { Header, Content, Footer } = Layout;

function Play() {
  

  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader/>

      </Header>
      <Content className="layout-content">
        <Cate_Play/>
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default Play;
