import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import DesignItemDetail from "../components/DesignItemDetailGame";
import { useContext } from "react";
import { StoreContext } from "../store"
import DesignA from "../json/DesignA.json";
 

const { Header, Content, Footer } = Layout;

function ItemDetailGame({match}) {
  const item = DesignA.find(
    (x) => x.id === match.params.itemID
  );
  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader/>

      </Header>
      <Content className="layout-content">
        <DesignItemDetail DesignA = {item}/>
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default ItemDetailGame;
