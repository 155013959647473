
import {Row, Col } from 'antd';

import DesignItemB from './DesignItemB'; //Package Design
import DesignB from '../json/DesignB.json';
import DesignItemE from './DesignItemE'; //Graphic Design
import DesignE from '../json/DesignE.json'; 


import widthImage from '../images/banner02.jpg';

export default function Cate_Play(){ // 視覺設計

    return( 
        <div>
            <div className="sub-cate-title mt-40">
                <h3>Package Design</h3>
                <p className="separator" />
            </div>
            <div className="box-Design">
                <Row className="rwd-design"  justify="center">
                    {DesignB.map((BClass) => (
                    <Col
                        key={BClass.id}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 12 }}
                        lg={{ span: 10 }}
                    >
                        <DesignItemB DesignB = {BClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            <div className="sub-cate-title">
                <h3>Graphic Design</h3>
                <p className="separator" />
            </div>
            <div className="box-Design">
                <Row className="rwd-design"  justify="center">
                    {DesignE.map((EClass) => (
                    <Col
                        key={EClass.id}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 12 }}
                        lg={{ span: 10 }}
                    >
                        <DesignItemE DesignE = {EClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            

        </div>
    );
}