
import {Row, Col } from 'antd';

import DesignItemA from './DesignItemA'; //Game Design
import DesignA from '../json/DesignA.json';
import DesignItemC from './DesignItemC'; //Service Design
import DesignC from '../json/DesignC.json';
import DesignItemD from './DesignItemD'; //Product Design
import DesignD from '../json/DesignD.json';

import { useContext } from "react";
import { StoreContext } from "../store"

import widthImage from '../images/banner02.jpg';

export default function Cate_UIUX(){ // 互動設計
    const { state: { page: {title} } } = useContext(StoreContext);

 
    return(
        <div className="container-fluid " >
            <div className="sub-cate-title mt-40">
                <h3>Game Design</h3>
                <p className="separator" />
            </div>
            <div className="box-Design">
                <Row className="rwd-design" >
                    {DesignA.map((AClass) => (
                    <Col
                        key={AClass.id}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <DesignItemA DesignA = {AClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            <div>
            <div className="sub-cate-title">
                <h3>Service Design</h3>
                <p className="separator" />
            </div>
                <div className="box-Design">
                    <Row className="rwd-design">
                        {DesignC.map((CClass) => (
                        <Col
                            key={CClass.id}
                            xs={{ span: 6 }}
                            sm={{ span: 6 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                        >
                            <DesignItemC DesignC = {CClass}/>
                        </Col>
                            ))}
                    </Row> 
                </div>
            </div>
            <div>
            <div className="mb-60"></div>
            <div className="sub-cate-title">
                <h3>Product Design</h3>
                <p className="separator" />
            </div>
                <div className="box-Design">
                    <Row className="rwd-design">
                        {DesignD.map((DClass) => (
                        <Col
                            key={DClass.id}
                            xs={{ span: 6 }}
                            sm={{ span: 6 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                        >
                            <DesignItemD DesignD = {DClass}/>
                        </Col>
                            ))}
                    </Row>
                </div>
                <div className="mb-60"></div>

            </div>

        </div>
    );
}