import {Link} from'react-router-dom';
import { Image, Row, Col, Card} from 'antd';


export default function DesignItemE( {DesignE}){
    return(
        <div className='item-box '>
            <Row className='mt-5 item-box-row' justify="center">
                
                <Col span={16}>
                    <Card
                        style={{ position: 'relative' }}
                        bordered={false}
                        cover=
                        {<Image 
                            alt="example" 
                            src={require(`../images/projects/graphic/${DesignE.image}`)}/>}
                        >
                            
                        <div>
                            <div className='design-name'>{DesignE.title}</div>
                            <div className='design-slogan'>{DesignE.slogan}</div>
                        </div>
                    </Card>
                </Col>
            </Row>
            
        </div>

    )
}