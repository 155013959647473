
import {Row, Col } from 'antd';

import DesignItemG from './DesignItemG'; // Advanced
import DesignG from '../json/DesignG.json';
import DesignItemF from './DesignItemF'; //Basic
import DesignF from '../json/DesignF.json';

import widthImage from '../images/banner02.jpg';

export default function Cate_Craft(){ // AR VR MR

    return( 
        <div>
            <div className="sub-cate-title mt-40">
                <h3>Basic</h3>
                <p className="separator" />
            </div>
            <div className="box-Design">
                <Row className="rwd-design"  justify="center" >
                    {DesignF.map((FClass) => (
                    <Col
                        key={FClass.id}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 12 }}
                        lg={{ span: 18 }}
                    >
                        <DesignItemF DesignF = {FClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            <div className="sub-cate-title">
                <h3>Advanced</h3>
                <p className="separator" />
            </div>
            <div className="box-Design">
                <Row className="rwd-design"  justify="center">
                    {DesignG.map((GClass) => (
                    <Col
                        key={GClass.id}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 12 }}
                        lg={{ span: 18 }}
                    >
                        <DesignItemG DesignG = {GClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            

        </div>
    );
}