import './App.css';
import './UpdateStyle.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Ntue from './pages/ntue';

import Craft from './pages/Craft';

import Play from './pages/Play';
import ItemDetailBasic from './pages/ItemDetailBasic';
import ItemDetailAdvanced from './pages/ItemDetailAdvanced';

import UIUX from './pages/UIUX';
import ItemDetailGame from './pages/ItemDetailGame';
import ItemDetailService from './pages/ItemDetailService';
import ItemDetailProduct from './pages/ItemDetailProduct';

import Contact from './pages/contact';
import { StoreProvider } from "./store";

function App() {
  return (
    <StoreProvider >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/record" component={Ntue} />

          <Route path="/VR" component={Craft} /> 
         
          <Route path="/VI" component={Play} /> 
          <Route path="/ItemDetailBasic/:itemID" component={ItemDetailBasic} />
          <Route path="/ItemDetailAdvanced/:itemID" component={ItemDetailAdvanced} />

          <Route path="/Interactive" component={UIUX} />
          <Route path="/ItemDetailGame/:itemID" component={ItemDetailGame} />
          <Route path="/ItemDetailService/:itemID" component={ItemDetailService} />
          <Route path="/ItemDetailProduct/:itemID" component={ItemDetailProduct} />

          <Route path="/contact" component={Contact} />

        </Switch>
      </BrowserRouter>
    </StoreProvider>


  );

}

export default App;
