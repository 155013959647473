import {Link} from'react-router-dom';
import { Image, Col, Row, Card} from 'antd';

export default function DesignItemB( {DesignB}  ){
    return(
        <div className='item-box '>
            <Row className='mt-5 item-box-row' justify="center">
                
                <Col span={16}>
                    <Card
                        style={{ position: 'relative' }}
                        bordered={false}
                        cover=
                        {<Image 
                            alt="example" 
                            src={require(`../images/projects/visual/${DesignB.image}`)}/>}
                        >
                            
                        <div >
                            <div className='design-name'>{DesignB.title}</div>
                            <div className='design-slogan'>{DesignB.slogan}</div>
                        </div>
                    </Card>
                </Col>
            </Row>
            
        </div>
    )
}