
import Gallery from './Gallery';


export default function Main_ntue(){

    return(
        <div> 
            {/* <Link to="/Grade1" ><img src={y1} width={50} className="grade-image"/></Link> */}
            <div className="container-fluid">
                <div className='ntue-item'>
                    <Gallery/>
                </div>
            </div>
        </div>
    );
}