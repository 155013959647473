import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import DesignItemDetail from "../components/DesignItemDetailAdvanced";
import DesignG from "../json/DesignG.json";


const { Header, Content, Footer } = Layout;

function ItemDetailAdvanced({match}) {
  const item = DesignG.find(
    (x) => x.id === match.params.itemID
  );
  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader/>

      </Header>
      <Content className="layout-content">
        <DesignItemDetail DesignG = {item}/>
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default ItemDetailAdvanced;
