import {Link} from'react-router-dom';
import { Image, Card, Row, Col} from 'antd';

export default function DesignItemD( {DesignD}  ){
   
    
    return(
        <Link to={`/ItemDetailProduct/${DesignD.id}` }>
            <div className='item-box type'>
                <Row className='item-box-row'justify="center">
                    
                    <Col>
                        <div>
                            <div className='design-rwd-box'>
                                    <div className='design-content' style={{flex: "1"}}>
                                        <div className='design-tag'>{DesignD.tag}</div>
                                        <div className='design-name mt-20'>{DesignD.title}</div>
                                        <div className='design-slogan'>{DesignD.slogan}</div>
                                    </div>
                                    <div style={{flex: "1"}}>
                                        <Image 
                                            alt="example" 
                                            preview={false}
                                            src={require(`../images/projects/product/${DesignD.image}`)}/>
                                        
                                    </div>
                            </div>
                            <div>
                                <div className='design-award mt-5'>{DesignD.award0}</div>
                                <div className='design-award'>{DesignD.award1}</div>
                                <div className='design-award'>{DesignD.award2}</div>
                            </div>
                        </div>
                        
                        
                    </Col>
                </Row>
                
            </div>
        </Link>
        


    )
}