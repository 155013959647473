import {Link} from'react-router-dom';
import { Image,  Col, Row, Card} from 'antd';
import {
    ExportOutlined
  } from '@ant-design/icons';


export default function DesignItemG( {DesignG}){
    return(
        <div className='item-box box type'>
                <Row justify="center" align="middle" >
                
                    <Col span={24}>
                        <a href={DesignG.youtube} target="_blank" rel="noopener noreferrer">
                            <div className='type-link'>
                                external link <span class="material-symbols-outlined"><ExportOutlined /></span>
                            </div>
                        </a>
                        
                        <div style={{display:"flex", position: "relative"}}>
                            <div style={{flex:"1"}}>
                                <Image 
                                    alt="example" 
                                    src={require(`../images/projects/program-video/${DesignG.image}`)}/> 
                            </div>
                            <div className='design-detail-words'  style={{flex:"1"}}>
                                <div class="tag">{DesignG.tag}</div>
                                <div className='design-detail-name'>{DesignG.name}</div>
                                <div className='design-detail-concept'style={{color: "gray"}}>Introduction ..... </div>
                                <div className='design-detail-concept'>{DesignG.content}</div>
                                <div className='design-detail-concept'style={{color: "gray"}}>What I made.....</div>
                                <div className='design-detail-concept'>{DesignG.reason}</div>
                            </div>
                            
                        </div>
                        
                        
                            
                    </Col>
                </Row>
                
            </div>


    )
}