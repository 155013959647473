import { NavLink } from 'react-router-dom';
import { Row, Col,  Drawer, Button ,Divider } from 'antd';
import React, { useState } from 'react';
import {MenuOutlined} from '@ant-design/icons';

export default function NavBar() {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    return (
        <div>
            <MenuOutlined className="icon-menu" onClick={showDrawer}/>
            <div className="navbar-nav">
                <NavLink to="/contact" className="navbar-item" activeClassName="active">
                    Contact
                </NavLink>
                <NavLink to="/record" className="navbar-item" activeClassName="active">
                    Record
                </NavLink>
                <NavLink to="/" className="navbar-item" activeClassName="active">
                    Home
                </NavLink>


            </div>

            <Drawer
                height={500}
                placement="top"
                closable={false}
                onClose={onClose}
                visible={visible}
                >
                <NavLink to="/" className="rwd-navbar-item" activeClassName="nav-item--active">
                    Home
                </NavLink>
                <Divider />
                <NavLink to="/ntue" className="rwd-navbar-item" activeClassName="nav-item--active">
                    Record
                </NavLink>
                <Divider />
                <NavLink to="/contact" className="rwd-navbar-item" activeClassName="nav-item--active">
                    Contact
                </NavLink>

            </Drawer>

        </div>

    );

}