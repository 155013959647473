import React, { useState } from 'react';
import NavBar from "./NavBar";
import NavBarRwd from "./NavBarRwd";
import { useContext } from "react";
import { StoreContext } from "../store"
import { Row, Col,  Drawer, Button } from 'antd';
import { Link } from 'react-router-dom';



export default function Header() {
  const { dispatch } = useContext(StoreContext);

    return (
      <div >
        <div className="header">
          <Row className="container" justify="space-between">
            <Col flex="50px">
              <Link to="/" className="site-name bar" activeClassName="active">Profolio</Link>
            </Col>
            <Col flex="auto"> 
              <NavBar/>
            </Col>
          </Row>
        </div>
      </div>
    );

  }