
import {Row, Col } from 'antd';
import React from 'react';
import {CaretDownFilled} from '@ant-design/icons';
import {Link} from 'react-router-dom';

export default function DesignItemDetailService({DesignC}){

    return(
        <div className='design-box'>
            <div className='design-detail-box'>
                <Row className='design-detail-box-row'>
                    <Col span={12}>
                        <div>
                            <div className='design-detail-tag'>{DesignC.tag}</div>
                            <div className='design-detail-name'>{DesignC.title}</div>
                            <div className='design-detail-concept'>{DesignC.concept}</div>
                        </div>
                    </Col>
                    <Col span={10}>
                        <div className='design-video'>
                            <iframe
                                className='design-video-iframe'
                                width="560"  // 设置视频宽度
                                height="315"  // 设置视频高度
                                src={DesignC.youtube} // 将 VIDEO_ID 替换为您要嵌入的YouTube视频的ID
                                frameborder="0"  // 用于消除边框
                                allowFullScreen  // 允许全屏
                            ></iframe>
                            
                        </div>
                    </Col>
                    
                </Row>
                <div className='arrow'><CaretDownFilled /></div>
            </div>
            
            
           
            <Row gutter={16} className='mt-40 detail-row'>
                <Col span={14}>
                    <div className='design-detail-title'>INSPIRATION</div>
                    <div className='design-detail-content'>{DesignC.inspiration}</div>
                </Col>
                <Col span={10}>
                    <img src={require(`../images/projects/interactive/${DesignC.subImage01}`)}  width={450}/>
                </Col>
            </Row>
            <Row gutter={16} className='mt-40 detail-row'>
                <Col span={10}>
                    <img src={require(`../images/projects/interactive/${DesignC.subImage02}`)}  width={430}/>
                </Col>
                <Col span={14}>
                    <div className='design-detail-title'>SITUATION</div>
                    <div className='design-detail-content'>{DesignC.situation}</div>
                </Col>
            </Row>
            <Row gutter={16} className='mt-40 mb-60 detail-row'>
                <Col span={14}>
                    <div className='design-detail-title'>SOLUTION</div>
                    <div className='design-detail-content'>{DesignC.solution}</div>
                </Col>
                <Col span={10}>
                    <img src={require(`../images/projects/interactive/${DesignC.subImage03}`)}  width={450}/>
                </Col>
            </Row>

            <Row className='detail-row'>
                <Col span={8}>
                    <div className="contact-title">Want to know more ?  </div>
                </Col>
                <Col span={14}>
                    <Link to="/contact"><div className='contact-btn '>Contact me ~</div></Link>
                </Col>
                <Col span={2}>
                    <Link to="/Interactive"><div className='back-btn '>Back</div></Link>
                </Col>
            </Row>
            
            
        </div>
        
    );
}