import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import Main_ntue from "../components/Main_ntue";

const { Header, Content, Footer } = Layout;

function ntue() {

  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader  />
      </Header>
      <Content className="layout-content">
        <Main_ntue />
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default ntue;
